import { useState } from 'react';
import { Row, Col, Drawer } from 'antd';
import { withTranslation } from 'react-i18next';
import Container from '../../common/Container';
import { Button } from '../../common/Button';
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
} from './styles';
import { Link } from 'react-router-dom';

const Header = ({ t }) => {
  const [visible, setVisibility] = useState(false);

  const toggleButton = () => {
    setVisibility(!visible);
  };

  const hideMenu = () => {
    setVisibility(false);
  };

  const MenuItem = () => {
    // const scrollTo = (id) => {
    //   const element = document.getElementById(id);
    //   element.scrollIntoView({
    //     behavior: 'smooth',
    //   });
    //   setVisibility(false);
    // };
    return (
      <>
        <CustomNavLinkSmall>
          <Link to="/">
            <Span onClick={hideMenu}>{t('Home')}</Span>
          </Link>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall>
          <Link to="/about-us">
            <Span onClick={hideMenu}>{t('About')}</Span>
          </Link>
        </CustomNavLinkSmall>
        {/* <CustomNavLinkSmall onClick={() => scrollTo('product')}>
          <Span>{t('Services')}</Span>
        </CustomNavLinkSmall> */}
        <CustomNavLinkSmall>
          <Link to="/services">
            <Span onClick={hideMenu}>{t('Services')}</Span>
          </Link>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall>
          <Link to="/careers">
            <Span onClick={hideMenu}>{t('Careers')}</Span>
          </Link>
        </CustomNavLinkSmall>

        {/** for scroll **/}
        {/* <CustomNavLinkSmall
          style={{ width: '180px' }}
          onClick={() => scrollTo('contact')}
        >
          <Span>
            <Button>{t('Contact')}</Button>
          </Span>
        </CustomNavLinkSmall> */}

        {/** for new page **/}
        <CustomNavLinkSmall style={{ width: '180px' }}>
          <Link to="/contact-us">
            <Span>
              <Button onClick={hideMenu}>{t('Contact')}</Button>
            </Span>
          </Link>
        </CustomNavLinkSmall>
      </>
    );
  };

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            <img
              src="/img/svg/logo.png"
              alt="logo.png"
              width="101px"
              height="64px"
            />
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={toggleButton}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} open={visible} onClose={toggleButton}>
          <Col style={{ marginBottom: '2.5rem' }}>
            <Label onClick={toggleButton}>
              <Col span={12}>
                <Menu>{t('Menu')}</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);

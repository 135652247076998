import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import 'antd/dist/antd.min.css';

import Router from './router';
import i18n from './translation';
import ScrollToTop from './services/scrollToTopService';

function App() {
  return (
    <BrowserRouter>
      <ToastContainer />
      <ScrollToTop />
      <I18nextProvider i18n={i18n}>
        <Router />
      </I18nextProvider>
    </BrowserRouter>
  );
}

export default App;

const routes = [
  {
    path: ['/', '/home'],
    exact: true,
    component: 'Home',
  },
  {
    path: ['/about-us'],
    exact: true,
    component: 'AboutUs',
  },
  {
    path: ['/contact-us'],
    exact: true,
    component: 'ContactUs',
  },
  {
    path: ['/services'],
    exact: true,
    component: 'Services',
  },
  {
    path: ['/careers'],
    exact: true,
    component: 'Careers',
  },
  {
    path: ['*'],
    component: 'PageNotFound',
  },
];

export default routes;

import { Row, Col } from 'antd';
import { withTranslation } from 'react-i18next';
import { SvgIcon } from '../../common/SvgIcon';
import Container from '../../common/Container';

// import i18n from 'i18next';
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  // Empty,
  FooterContainer,
  Language,
  // Label,
  // LanguageSwitch,
  // LanguageSwitchContainer,
} from './styles';

const Footer = ({ t }) => {
  // const handleChange = (language) => {
  //   i18n.changeLanguage(language);
  // };

  const SocialLink = ({ href, src }) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Language>{t('Contact')}</Language>
              {/* <Large to="/">{t("Tell us everything")}</Large> */}
              <Para>
                <a href="tel:630-999-8227">
                  <Para>Phone: 630-999-8227</Para>
                </a>
              </Para>
              <Para>
                {t(`Do you have any question? Feel free to reach out.`)}
              </Para>
              <a href="mailto:mgmt@basesgroupinc.com">
                <Chat>{t(`Let's Chat`)}</Chat>
              </a>
            </Col>
            <Col lg={8} md={8} sm={12} xs={12}>
              <Title>{t('Address')}</Title>
              <Para>Elk Grove Village</Para>
              <Para>901 Biesterfield Rd</Para>
              <Para>IL 60007, United States</Para>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <Title>{t('Company')}</Title>
              <Large to="/about-us">{t('About')}</Large>
              <Large to="/services">{t('Services')}</Large>
              <Large to="/careers">{t('Careers')}</Large>
            </Col>
          </Row>
          {/* <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Empty />
              <Language>{t('Address')}</Language>
              <Para>Elk Grove Village</Para>
              <Para>901 Biesterfield Rd</Para>
              <Para>IL 60007, United States</Para>
            </Col>
            <Col lg={8} md={8} sm={12} xs={12}>
              <Title>{t('Company')}</Title>
              <Large to="/">{t('About')}</Large>
              <Large to="/">{t('Blog')}</Large>
              <Large to="/">{t('Press')}</Large>
              <Large to="/">{t('Careers & Culture')}</Large>
            </Col> */}

          {/* Language Switch */}
          {/* <Col lg={6} md={6} sm={12} xs={12}>
              <Label htmlFor="select-lang">{t('Language')}</Label>
              <LanguageSwitchContainer>
                <LanguageSwitch onClick={() => handleChange('en')}>
                  <SvgIcon
                    src="united-states.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
                <LanguageSwitch onClick={() => handleChange('es')}>
                  <SvgIcon
                    src="spain.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
              </LanguageSwitchContainer>
            </Col> 
          </Row>*/}
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: '3rem' }}
          >
            <NavLink to="/">
              <LogoContainer>
                <SvgIcon
                  src="logo.png"
                  aria-label="homepage"
                  width="101px"
                  height="64px"
                />
              </LogoContainer>
            </NavLink>
            <FooterContainer>
              {/* <SocialLink
                href="https://bases-frontend.vercel.app/"
                src="github.svg"
              /> */}
              <SocialLink
                href="https://www.linkedin.com/company/bases-autism-services-inc"
                src="linkedin.svg"
              />
              <SocialLink
                href="https://www.facebook.com/profile.php?id=61558736297207"
                src="facebook.svg"
              />
              <SocialLink
                href="https://www.instagram.com/basesgroupinc/"
                src="instagram.svg"
              />
              {/* <a
                href="https://ko-fi.com/Y8Y7H8BNJ"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  height="36"
                  style={{ border: 0, height: 36 }}
                  src="https://storage.ko-fi.com/cdn/kofi3.png?v=3"
                  alt="Buy Me a Coffee at ko-fi.com"
                />
              </a> */}
            </FooterContainer>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);

// import { SvgIconProps } from "../types";

export const SvgIcon = ({ src, width, height, resize }) => (
  <img
    src={`/img/svg/${src}`}
    alt={src}
    width={width}
    height={height}
    style={{ objectFit: resize }}
  />
);
